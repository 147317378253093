<template>
  <h1 id="clock" v-if="now && end && !finished">
    <time>{{display}}</time>
  </h1>
</template>

<script>
  export default {
    name: "countdown",
    props: {
      seconds: Number,
      done: Function
    },
    data() {
      return {
        now: null,
        end: null,
        tick: null
      }
    },
    watch: {
      now() {
        if (this.finished) {
          this.done()
          clearInterval(this.tick)
        }
      }
    },
    computed: {
      remaining() {
        return this.end.diff(this.now).toObject()
      },
      display() {
        return this.$Duration.fromObject(this.remaining).toFormat("ss")
      },
      finished() {
        return this.now >= this.end
      }
    },
    mounted() {
      if(this.seconds) {
        this.now = this.$DateTime.local()
        this.end = this.now.plus({ seconds: parseInt(this.seconds) })
  
        this.tick = setInterval(() => {
          this.now = this.$DateTime.local().set({ milliseconds: 0 })
        }, 1000)
      }
    }
  }
</script>