<template>
  <div class="scanner d-flex flex-column h-100 w-100 align-items-center justify-content-center">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center">
        <div class="col-12">
          <div class="text-center mb-5 d-flex justify-content-center" v-if="showButtons">
            <div class="btn-group mx-1" v-if="(currentSchedule && !currentSchedule.special)">
              <button type="button" class="btn" :class="{ 'btn-success': half, 'btn-outline-dark': !half }" @click="toggleHalf()">
                <font-awesome-icon icon="star-half" size="4x" fixed-width /><br>
                Halv
              </button>
            </div>

            <div class="btn-group mx-1">
              <button type="button" class="btn" :class="{ 'btn-success': toGo, 'btn-outline-dark': !toGo }" @click="toGo = !toGo">
                <font-awesome-icon icon="shopping-bag" size="4x" fixed-width /><br>
                Hämta
              </button>
            </div>

            <div class="btn-group ml-5 mr-1">
              <button type="button" class="btn" :class="{ 'btn-success': balance, 'btn-outline-dark': !balance }" @click="balance = !balance">
                <font-awesome-icon icon="balance-scale" size="4x" fixed-width /><br>
                Saldo
              </button>
            </div>

            <div class="btn-group ml-5 mr-1" v-if="testMode">
              <button type="button" class="btn" :class="{ 'btn-success': testMode, 'btn-outline-dark': !testMode }">
                <font-awesome-icon icon="vial" size="4x" fixed-width /><br>
                TESTLÄGE
              </button>
            </div>
          </div>

          <div class="card rounded-pill" :class="{ 'bg-warning': testMode }">
            <div class="card-body">
              <div class="loading text-center" v-if="state == 'loading'">
                <font-awesome-icon icon="spinner" size="4x" spin fixed-width />
                <h1 class="mt-2 mb-0 animate-flicker">Laddar</h1>
              </div>

              <div class="waiting text-danger text-center" v-else-if="state == 'closed'">
                <font-awesome-icon icon="exclamation-triangle" size="4x" fixed-width />
                <h1 class="mt-2 mb-0">STÄNGT</h1>
              </div>

              <div class="waiting text-danger text-center" v-else-if="state == 'error'">
                <font-awesome-icon icon="exclamation-triangle" size="4x" fixed-width />
                <h1 class="mt-2 mb-0">{{ error }}</h1>
              </div>

              <div class="waiting text-center" v-else-if="state == 'waiting'">
                <font-awesome-icon icon="id-card" size="4x" fixed-width />
                <h1 class="mt-2 mb-0">{{ scanPhrase }}</h1>
                <h4 class="m-0" v-if="showVoucherCost">{{ voucherCostMessage }}</h4>
              </div>

              <div class="waiting text-center" v-else-if="state == 'searching'">
                <font-awesome-icon icon="search" size="4x" fixed-width />
                <h1 class="mt-2 mb-0 animate-flicker">Letar efter kort</h1>
              </div>

              <div class="waiting text-center" v-else-if="state == 'success'">
                <h1>{{ successMessage }}</h1>
                <h2 class="m-0" v-if="voucherCost > 0">{{ vouchersDrawnMessage }}</h2>
                <h3 class="m-0">{{ voucherCountMessage }}</h3>
              </div>
            </div>
          </div>

          <div class="text-center mt-5 d-flex align-items-center flex-column" v-if="showButtons">
            <b>Antal portioner</b><br>
            <div class="portions input-group input-group-lg">
              <div class="input-group-prepend">
                <button type="button" class="btn btn-dark" @click="decreasePortions()" :disabled="portions == 1">
                  <font-awesome-icon icon="minus" fixed-width />
                </button>
              </div>
              
              <input type="number" name="numberOfPortions" id="numberOfPortions" v-model="portions" class="form-control text-center" @change="portionsChanged">
              
              <div class="input-group-append">
                <button type="button" class="btn btn-dark" @click="increasePortions()">
                  <font-awesome-icon icon="plus" fixed-width />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="countown position-absolute" v-if="countdownSeconds">
      <countdown class="text-white" :seconds="countdownSeconds" :done="countdownDone"></countdown>
    </div>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"

  import Countdown from "@/frontend/components/countdown"

  export default {
    name: "scanner",
    components: { Countdown },
    props: {
      scanComplete: {
        type: Function,
        default: () => {}
      },
      countdownComplete: {
        type: Function,
        default: () => {}
      },
      showButtons: {
        type: Boolean,
        default: true
      },
      voucherCostChanged: {
        type: Function,
        default: () => {}
      },
      showVoucherCost: {
        type: Boolean,
        default: false
      },
      successPhrase: {
        type: String,
        default: "Välkommen, %{name}"
      },
      voucherCost: {
        type: Number,
        default: 0
      },
      voucherCostPhrase: {
        type: String,
        default: "%{voucherCost} kommer dras"
      },
      voucherDrawnPhrase: {
        type: String,
        default: "%{voucherCost} dragna"
      },
      voucherCountPhrase: {
        type: String,
        default: "Du har totalt %{voucherCount} kvar"
      },
      scanPhrase: {
        type: String,
        default: "Skanna kort"
      },
      secondsToWait: {
        type: Number,
        default: 5
      }
    },
    data() {
      return {
        people: [],
        state: "loading",
        error: null,
        counter: 0,
        cardNumber: "",
        foundPerson: null,
        countdownSeconds: null,
        currentSchedule: null,
        checkDayTimeout: null,
        testMode: false,
        half: false,
        toGo: false,
        balance: false,
        portions: 1
      }
    },
    computed: {
      voucherCount() {
        let voucherCount = 0

        if(this.foundPerson) {
          if(this.foundPerson.vouchers && this.foundPerson.vouchers.length > 0) {
            if(this.foundPerson.vouchers.length > 1) {
              let voucherAmounts = this.foundPerson.vouchers.map((voucher) => voucher.amount)
              voucherCount = voucherAmounts.reduce((initial, voucher) => parseInt(initial) + parseInt(voucher))
            } else {
              voucherCount = this.foundPerson.vouchers[0].amount
            }
          }
        }
        
        return `${voucherCount} ${voucherCount == 1 ? "kupong" : "kuponger"}`
      },
      successMessage() {
        if(this.foundPerson) {
          return this.successPhrase.replace(`%{name}`, this.foundPerson.fullName)
        } else {
          return null
        }
      },
      voucherCostMessage() {
        let phrase = `${this.voucherCost} ${this.voucherCost == 1 ? "kupong" : "kuponger"}`
        return this.voucherCostPhrase.replace(`%{voucherCost}`, phrase)
      },
      vouchersDrawnMessage() {
        let phrase = `${this.voucherCost} ${this.voucherCost == 1 ? "kupong" : "kuponger"}`
        return this.voucherDrawnPhrase.replace(`%{voucherCost}`, phrase)
      },
      voucherCountMessage() {
        return this.voucherCountPhrase.replace(`%{voucherCount}`, this.voucherCount)
      }
    },
    methods: {
      clearData() {
        this.counter = 0
        this.cardNumber = ""
        this.foundPerson = null
      },
      handleMousetrap(event) {
        if(this.counter == 0) { this.clearData() }

        let pressedKey = event.which || event.keyCode
        let character = String.fromCharCode(pressedKey)

        this.counter = this.counter + 1

        if(pressedKey == 13) {
          this.state = "searching"
          this.getUserByCardNumber()
        } else {
          this.cardNumber = `${this.cardNumber}${character}`
        }
      },
      toggleHalf() {
        this.half = !this.half

        this.voucherCostChanged(this.currentSchedule, this.half, this.portions)
      },
      portionsChanged() {
        if(parseInt(this.portions) < 1) {
          this.portions = 1
        }

        this.voucherCostChanged(this.currentSchedule, this.half, parseInt(this.portions))
      },
      decreasePortions() {
        this.portions = parseInt(this.portions) - 1
        this.voucherCostChanged(this.currentSchedule, this.half, this.portions)
      },
      increasePortions() {
        this.portions = parseInt(this.portions) + 1
        this.voucherCostChanged(this.currentSchedule, this.half, this.portions)
      },
      async getUserByCardNumber() {
        this.$mousetrap.pause()
        this.foundPerson = null

        try {
          let people = this.people.filter((person) => person.cardNumber == this.cardNumber)

          if(people.length == 0) {
            throw `Kortnummer kunde inte hittas.`
          } else if(people.length > 1) {
            throw `Kortnummer finns på flera personer.`
          } else {
            let voucherCount = 0
            let person = people[0]
            this.foundPerson = person

            if(person.vouchers && person.vouchers.length > 0) {
              if(person.vouchers.length > 1) {
                let voucherAmounts = person.vouchers.map((voucher) => voucher.amount)
                voucherCount = voucherAmounts.reduce((initial, voucher) => parseInt(initial) + parseInt(voucher))
              } else {
                voucherCount = person.vouchers[0].amount
              }
            }

            if(!this.testMode && voucherCount == 0) {
              let message = `Du har inga kuponger kvar.`
              let logData = {
                who: person.id,
                what: "used_card",
                to: person.id,
                half: this.half,
                toGo: this.toGo,
                when: this.$DateTime.local().toISO(),
                portions: this.portions,
                cost: 0,
                voucherCost: 0,
                newAmount: 0,
                failed: true,
                message: message
              }

              await firebase.firestore().collection(`logs`).add(logData)

              throw message
            } else {
              if(!this.balance) {
                await this.scanComplete(this.testMode, person, this.currentSchedule, this.half, this.toGo, this.portions)
                this.portions = 1
              }

              this.state = "success"
            }
          }
        } catch(error) {
          this.state = "error"
          this.error = error
        }

        this.countdownSeconds = this.secondsToWait
        
        this.half = false
        this.toGo = false
        this.balance = false

        this.voucherCostChanged(this.currentSchedule, this.half, this.portions)
      },
      countdownDone() {
        this.countdownSeconds = null

        this.state = "waiting"
        this.clearData()
        this.countdownComplete(this.currentSchedule)

        this.$mousetrap.unpause()
        this.counter = 0
      },
      async getSchedule() {
        return new Promise((resolve, reject) => {
          try {
            let now = this.$DateTime.local()

            firebase.firestore().collection(`schedules`).where("year", "==", now.year.toString()).limit(1).get().then(async (result) => {
              if(result.docs.length == 0) {
                this.state = "closed"
              } else {
                let scheduleRef = result.docs[0]
                let schedule = await scheduleRef.data()

                let todaysSchedule = Object.values(schedule.days).find((scheduleDay) => scheduleDay.day == now.toFormat("yyyy-MM-dd"))
                
                if(!todaysSchedule) {
                  todaysSchedule = schedule.days[now.weekday]
                }

                if(!todaysSchedule || !todaysSchedule.active) {
                  this.state = "closed"
                } else {
                  this.currentSchedule = todaysSchedule
                }
              }

              this.checkDay()

              resolve()
            })
          } catch(error) {
            reject(error)
          }
        })
      },
      checkDay() {
        let now = this.$DateTime.local()
        let start = now.endOf("day")
        let diff = start.diff(now)

        this.checkDayTimeout = setTimeout(() => {
          let now = this.$DateTime.local()
          let newDiff = start.diff(now)

          if(newDiff <= 0) {
            this.getSchedule()
          } else {
            this.checkDay()
          }
        }, diff)
      }
    },
    async mounted() {
      if(this.checkDayTimeout) { clearTimeout(this.checkDayTimeout) }

      this.$mousetrap.reset()

      await this.getSchedule()

      if(this.currentSchedule) {
        this.voucherCostChanged(this.currentSchedule, this.half, this.portions)

        await this.$bind("people", firebase.firestore().collection(`people`))
        this.state = "waiting"
  
        this.$mousetrap.bind(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "enter"], this.handleMousetrap)
      }

      let url = new URL(window.location.href)
      let testModeParam = url.searchParams.get("testMode")
      this.testMode = (testModeParam == "true")
    },
    destroyed() {
      this.$mousetrap.reset()
      if(this.checkDayTimeout) { clearTimeout(this.checkDayTimeout) }
    }
  }
</script>

<style scoped lang="scss">
  .scanner {
    padding: 0px 120px;
  }

  .countown {
    top: 20px;
    right: 20px;
  }

  @keyframes flickerAnimation {
    0%   { opacity: 1; }
    50%  { opacity: 0; }
    100% { opacity: 1; }
  }

  .animate-flicker {
    animation: flickerAnimation 1s infinite;
  }

  .portions {
    max-width: 100%;
    width: 180px;
  }

  #numberOfPortions::-webkit-outer-spin-button,
  #numberOfPortions::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  #numberOfPortions[type=number] {
    -moz-appearance: textfield;
  }
</style>
